import { useNavigate, useParams } from 'react-router-dom'
import { Language } from '../../api/API.types'
import { CountryFlag } from '../CountryFlag/CountryFlag'
import './TitleBar.css'
import { useContext } from 'react';
import { LanguageContext } from '../../App';

export function TitleBar() {
    const {activeLang} = useParams();
    const navigate = useNavigate();
    const languages = useContext(LanguageContext);
    
    function setLanguage(lang: Language) {
        const basePath = window.location.pathname;
        const newPath = basePath.replace(`/${activeLang}`, `/${lang.id}`);
        localStorage.setItem("preferredLang", lang.id);
        navigate(newPath);
    }

    return <div className='TitleBar'>
        <div className='TitleBar-Section' style={{justifyContent: 'left'}}>

        </div>
        <div className='TitleBar-Section' style={{justifyContent: 'center'}}>
            <h1><a className='TitleBar-TitleLink' href={`/${activeLang}`}>TXTDump</a></h1>
        </div>
        <div className='TitleBar-Section' style={{justifyContent: 'right'}}>
            {languages?.map(lang => 
                <div
                    key={lang.id}
                    className={lang.id === activeLang ? "TitleBar-ActiveLanguageFlag" : "TitleBar-LanguageFlag"}
                    onClick={() => setLanguage(lang)}
                >
                    <CountryFlag alt={lang.name} country={lang.id}/>
                </div>
            )}
        </div>
    </div>
}