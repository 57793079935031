import { l18nRepository } from "../../../global.types";

export const COLLECTION_LIST_L18N_REPO: l18nRepository = {
    "No Data": {
        "en": "No data to show.",
        "de": "Keine Daten anzuzeigen.",
        "jp": "データなし"
    },
    "Top Level Collection": {
        "en": "New Top-Level Collection",
        "de": "Neue übergeordnete Sammlung",
        "jp": "新しいトップレベルのコレクション"
    }
}