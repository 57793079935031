import { useState } from 'react';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';
import './LoadableImage.css';

type LoadableImageProps = {
    url: string,
    alt: string,
    width: number | string,
    height: number | string,
    className?: string
}

export function LoadableImage(props: LoadableImageProps) {
    const containerStyle = {
        width: props.width,
        height: props.height
    }
    const [loading, setLoading] = useState<boolean>(true);

    return <div className='LoadableImage' style={containerStyle}>
        {loading && <LoadSpinner/>}
        <img
            className={`LoadableImage-Image ${props.className}`}
            src={props.url}
            alt={props.alt}
            onLoad={() => setLoading(false)}
        />
    </div>
}