import { l18nRepository } from "../../global.types"
import { useParams } from "react-router-dom"

type LocalizedStringProps = {
    repo: l18nRepository,
    children: string
}

export function LocalizedString(props: LocalizedStringProps) {
    const {activeLang} = useParams();
    const str = props.children;

    if(!props.repo[str] || !props.repo[str][activeLang as string])
        return <code><b style={{color: "red"}}>!</b> {`MISSING_L18N_${str}_${activeLang}`} <b style={{color: "red"}}>!</b></code>;
    else
        return <div dangerouslySetInnerHTML={{__html: props.repo[str][activeLang as string]}}/>
}