import { l18nRepository } from "../../global.types";

export const TEXT_DISPLAY_L18N_REPO: l18nRepository = {
    "Collections": {
        "en": "Collections",
        "de": "Sammlungen",
        "jp": "コレクション",
    },
    "Text": {
        "en": "Text Records",
        "de": "Texte",
        "jp": "テクスト",
    },
    "Options:": {
        "en": "Options:",
        "de": "Optionen:",
        "jp": "設定:"
    },
    "Immersive Mode": {
        "en": "Immersive Mode",
        "de": "Immersiver Modus",
        "jp": "没入型モード"
    }
}