import { useEffect, useState } from "react"
import { Collection } from "../../../api/API.types"
import { API } from "../../../api/API";
import { CollectionEntry } from "../CollectionEntry/CollectionEntry";
import { LoadSpinner } from "../../LoadSpinner/LoadSpinner";
import './CollectionList.css';
import { LocalizedString } from "../../LocalizedString/LocalizedString";
import { COLLECTION_LIST_L18N_REPO } from "./CollectionList.l18n";
import { useParams } from "react-router-dom";
import { Button } from "../../Button/Button";

type CollectionListProps = {
    game: string,
    active?: string,
    onSelect: (id: string) => any
}

export function CollectionList(props: CollectionListProps) {
    const editMode = process.env.NODE_ENV === "development";
    const [collections, setCollections] = useState<Collection[] | null>(null);
    const {activeLang} = useParams();
    const loadCollections = async () => setCollections(await API.getCollections(activeLang as string, props.game));

    useEffect(() => {
        setCollections(null);
        loadCollections();
    }, [activeLang]); // eslint-disable-line react-hooks/exhaustive-deps

    async function onAddSubcollection(name: string, id: string, parent: string) {
        setCollections(null);
        await API.createCollection(activeLang as string, props.game, id, name, parent);
        loadCollections();
    }

    async function onAddTopLevelCollection() {
        const id = prompt("Enter Top Level Collection ID");
        if(!id)
            return;

        const displayName = prompt("Enter display name of collection");
        if(!displayName)
            return;

        setCollections(null);
        await API.createCollection(activeLang as string, props.game, id, displayName, null);
        loadCollections();
    }

    const collectionsList = collections
        ? collections.filter(c => c.parent === null).map(c => <CollectionEntry onAddSubcollection={onAddSubcollection} active={props.active} key={c.id} collections={collections} tlid={c.id} onSelect={props.onSelect}/>)
        : <LoadSpinner/>

    if(collections?.length === 0)
        return <>
            {editMode && <Button onClick={onAddTopLevelCollection}><LocalizedString repo={COLLECTION_LIST_L18N_REPO}>Top Level Collection</LocalizedString></Button>}
            <p className="CollectionList-NoDataLabel"><LocalizedString repo={COLLECTION_LIST_L18N_REPO}>No Data</LocalizedString></p>
        </>

    return <>
        {editMode && <Button onClick={onAddTopLevelCollection}><LocalizedString repo={COLLECTION_LIST_L18N_REPO}>Top Level Collection</LocalizedString></Button>}
        {collectionsList}
    </>
}