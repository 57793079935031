import { Link } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { LocalizedString } from '../../components/LocalizedString/LocalizedString';
import { GAMELIST_L18N_REPO } from '../GamesList/GamesList.l18n';
import './Home.css'
import { HOME_L18N_REPO } from './Home.l18n';
import { TitleBar } from '../../components/TitleBar/TitleBar';

export function Home() {
    return (<>
        <TitleBar/>
        <div className="Home">
            <div className='Home-TextContainer'>
                <p><LocalizedString repo={HOME_L18N_REPO}>Welcome</LocalizedString></p>
                <p><LocalizedString repo={HOME_L18N_REPO}>WIP Note</LocalizedString></p>

                <Link to="games">
                    <Button><LocalizedString repo={GAMELIST_L18N_REPO}>Games</LocalizedString></Button>
                </Link>

                <br/><br/><br/>

                <h2><LocalizedString repo={HOME_L18N_REPO}>How does this work?</LocalizedString></h2>
                <p><LocalizedString repo={HOME_L18N_REPO}>TXTDump Explanation</LocalizedString></p>
                
                <h2><LocalizedString repo={HOME_L18N_REPO}>Can I use?</LocalizedString></h2>
                <p><LocalizedString repo={HOME_L18N_REPO}>TXTDump Use</LocalizedString></p>

                <h2><LocalizedString repo={HOME_L18N_REPO}>Contact</LocalizedString></h2>
                <p><LocalizedString repo={HOME_L18N_REPO}>Contact Text</LocalizedString></p>
            </div>
        </div>
    </>);
}