import { useNavigate, useParams } from "react-router-dom"
import { CollectionList } from "../../components/TextDisplayPage/CollectionList/CollectionList";
import { LocalizedString } from "../../components/LocalizedString/LocalizedString";
import { TEXT_DISPLAY_L18N_REPO } from "./TextDisplayPage.l18n";
import './TextDisplayPage.css'
import { NodeFlowContainer } from "../../components/TextDisplayPage/NodeFlowContainer/NodeFlowContainer";
import { ToggleButton } from "../../components/ToggleButton/ToggleButton";
import { useState } from "react";
import { TitleBar } from "../../components/TitleBar/TitleBar";

type TextDisplayPageParams = {
    title: string,
    activeLang: string,
    collection?: string
}

export function TextDisplayPage() {
    const navigate = useNavigate();
    const {activeLang, title, collection} = useParams() as TextDisplayPageParams;

    const [immersive, setImmersive] = useState(false);

    return <>
        <TitleBar/>
        <div className="TextDisplayPage">
            <div className="TextDisplayPage-CollectionsContainer">
                <h2><LocalizedString repo={TEXT_DISPLAY_L18N_REPO}>Collections</LocalizedString></h2>
                <CollectionList
                    game={title}
                    active={collection}
                    onSelect={(collection: string) => navigate(`/${activeLang}/games/${title}/${collection}`)}
                />
            </div>
            <div className="TextDisplayPage-NodeFlowContainer">
                <h2><LocalizedString repo={TEXT_DISPLAY_L18N_REPO}>Text</LocalizedString></h2>
                <div className="TextDisplayPage-Toolbox">
                    <h3><LocalizedString repo={TEXT_DISPLAY_L18N_REPO}>Options:</LocalizedString></h3>
                    <ToggleButton enabled={immersive} onChange={() => setImmersive(!immersive)}><LocalizedString repo={TEXT_DISPLAY_L18N_REPO}>Immersive Mode</LocalizedString></ToggleButton>
                </div>
                <NodeFlowContainer immersive={immersive} game={title} collection={collection}/>
            </div>
        </div>
    </>
}