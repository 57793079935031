import { l18nRepository } from "../../global.types";

export const HOME_L18N_REPO: l18nRepository = {
    "Welcome": {
        "en": "Welcome to TXTDump, a public repository of game scripts in various languages.",
        "de": "Willkommen auf TXTDump, einer öffentlichen Sammlung von Spieletrankripten in unterschiedlichen Sprachen.",
        "jp": "TXTDumpのサイトに、よろしく！このサイトは色々な言語のゲームのスクリプトのコレクションです。",
    },
    "WIP Note": {
        "en": "Note that this site is heavily Work-In-Progress and subject to large changes without prior notice.",
        "de": "Diese Seite ist zurzeit noch in Entwicklung und kann sich jederzeit ohne Vorwarnung drastisch ändern.",
        "jp": "なお、本サイトはWork-In-Progressであり、予告なしに大きく変更される場合があります。",
    },
    "How does this work?": {
        "en": "How does this work?",
        "de": "Wie funktioniert die Seite?",
        "jp": "これはどのような仕組みなのか？",
    },
    "TXTDump Explanation": {
        "en": "TXTDump has it's own database containing scripts from games in various languages. You can view these scripts through this web interface. Additionally, you can contribute to already existing scripts if you find text which is not yet present in the TXTDump database. (Note: This feature is not available yet)",
        "de": "TXTDump betreibt eine Datenbank in welcher Transkripte von Spielen in unterschiedlichen Sprachen festgehalten werden. Du kannst diese Transkripte über diese Weboberfläche einsehen. Außerdem kannst du neue Texte zu schon vorhandenen Transkripten beitragen, sollten diese noch nicht vorhanden sein. (Achtung: Diese Funktionalität ist noch nicht verfügbar)",
        "jp": "TXTDumpには、様々な言語のゲームのスクリプトを含む独自のデータベースがあります。これらのスクリプトはこのウェブインタフェースから閲覧することができます。さらに、TXTDumpデータベースにまだ存在しないテキストを見つけた場合、既存のスクリプトに貢献することができます。(注意：この機能はまだ利用できません)",
    },
    "Can I use?": {
        "en": "Can I use this database somehow?",
        "de": "Kann ich diese Datenbank verwenden?",
        "jp": "このデータベースをどうにかして使うことはできますか？",
    },
    "TXTDump Use": {
        "en": "Yes! There is a free, public API available which is used by this Web UI. Documentation on how to use it for yourself is currently in development.",
        "de": "Ja! Eine kostenlose, öffentliche API ist verfügbar, welche auch von dieser Website verwendet wird. Dokumentation hierfür ist zurzeit in Arbeit.",
        "jp": "はい！このWeb UIで使用されている無料の公開APIがあります。使い方のドキュメントは現在開発中です。"
    },
    "Contact": {
        "en": "Contact",
        "de": "Kontakt",
        "jp": "連絡",
    },
    "Contact Text": {
        "en": "This site as well as the corresponding database and API are developed and maintained by Hacktix™ (hacktixtm on Discord). Feel free to message me there in case of any requests, questions or otherwise!",
        "de": "Sowohl diese Seite als auch die zugehörige Datenbank und API werden von Hacktix™ (hacktixtm auf Discord) entwickelt und instand gehalten. Bei Fragen, Wünschen oder anderen Anliegen, schreibe mir gerne!",
        "jp": "このサイトと対応するデータベースとAPIはHacktix™（Discordのhacktixtm）によって開発・管理されています。何か要望や質問などがあれば、遠慮なくそこにメッセージを送ってください！",
    }
}