import { l18nRepository } from "../../global.types";

export const GAMELIST_L18N_REPO: l18nRepository = {
    "Games": {
        "en": "Available Games",
        "de": "Verfügbare Spiele",
        "jp": "使用可能なゲーム",
    },
    "No games": {
        "en": "No games available. :(",
        "de": "Keine Spiele verfügbar. :(",
        "jp": "ゲームがない。:("
    }
}