import { l18nRepository } from "../../../global.types";

export const NODE_FLOW_L18N_REPO: l18nRepository = {
    "No Data": {
        "en": "No data to show.",
        "de": "Keine Daten anzuzeigen.",
        "jp": "データなし"
    },
    "Select Collection": {
        "en": "Please select a collection.",
        "de": "Bitte wähle eine Sammlung.",
        "jp": "コレクションを選んでください。"
    },
    "Immersive Mode Instructions": {
        "en": "Continue/Skip: Left Click | Go Back: Right Click",
        "de": "Weiter/Überspringen: Linksklick | Zurück: Rechtsklick",
        "jp": "続く/スキップ: 左クリック | 戻る: 右クリック",
    },
    "Append To End": {
        "en": "Append New Node to End",
        "de": "Neue Node an Ende anhängen",
        "jp": "新しいノードを付する",
    }
}