import './ToggleButton.css';

type ToggleButtonProps = {
    enabled: boolean,
    children: any,
    onChange: Function,
}

export function ToggleButton(props: ToggleButtonProps) {
    const className = props.enabled ? "ToggleButton on" : "ToggleButton off";

    return <div className={className} onClick={() => props.onChange()}>
        {props.children}
    </div>
}