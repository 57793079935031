import { Language, Game, Collection, Node } from "./API.types";

export class API {

    static apiURL: string = process.env.REACT_APP_API_URL || "https://citadel.ix.tc:6969/txtdump";

    static async getLanguages(): Promise<Language[]> {
        try {
            return (await fetch(`${API.apiURL}/lang`)).json();
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            return []
        }
    }

    static async getGames(lang: string): Promise<Game[]> {
        try {
            return (await fetch(`${API.apiURL}/games?lang=${lang}`)).json();
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            return [];
        }
    }

    static async getCollections(lang: string, game: string): Promise<Collection[]> {
        try {
            return (await fetch(`${API.apiURL}/collections?lang=${lang}&game=${game}`)).json();
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            return [];
        }
    }

    static async getNodes(lang: string, game: string, collection: string): Promise<Node[]> {
        try {
            return (await fetch(`${API.apiURL}/nodes?lang=${lang}&game=${game}&collection=${collection}`)).json();
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            return [];
        }
    }



    static async createNode(lang: string, game: string, collection: string, content: string, speaker: string | null, prev: number | null, merge: boolean): Promise<Response> {
        try {
            const options = {
                method: "PUT",
                body: JSON.stringify({
                    lang, game, collection, content, speaker, prev, merge: (+merge)
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            };
            return await fetch(`${API.apiURL}/nodes`, options);
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            throw e;
        }
    }

    static async createCollection(lang: string, game: string, id: string, name: string, parent: string | null): Promise<Response> {
        try {
            const options = {
                method: "PUT",
                body: JSON.stringify({
                    lang, game, id, name, parent
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            };
            return await fetch(`${API.apiURL}/collections`, options);
        } catch(e) {
            alert("A network error has occurred. Make sure you are online, then reload the page.");
            throw e;
        }
    }

}