import { useState } from "react";
import { Collection } from "../../../api/API.types"
import './CollectionEntry.css';

type CollectionEntryProps = {
    collections: Collection[],
    tlid: string,
    onSelect: (id: string) => any,
    active?: string,
    onAddSubcollection: (name: string, id: string, parent: string) => any,
}

export function CollectionEntry(props: CollectionEntryProps) {

    function shouldStartExpanded() {
        let activeCol = props.collections.find(c => c.id === props.active);
        if(!activeCol)
            return false;
        if(activeCol.id === props.tlid || activeCol.parent === props.tlid)
            return true;

        while(activeCol.parent) {
            // eslint-disable-next-line no-loop-func
            activeCol = props.collections.find(c => c.id === (activeCol as Collection).parent);
            if(!activeCol)
                return false;
            if(activeCol.parent === props.tlid)
                return true;
        }
        return false;
    }

    const editMode = process.env.NODE_ENV === "development";
    const topLevel: Collection = props.collections.find(c => c.id === props.tlid) as Collection;
    const children: Collection[] = props.collections.filter(c => c.parent === topLevel.id);
    const titleClass = "CollectionEntry-Title" + (props.active && props.active === props.tlid ? " active" : "");

    const [expanded, setExpanded] = useState(shouldStartExpanded());
    const buttonTitle = (children.length > 0 ? (expanded ? "▼" : "▶") + " " : "") + topLevel.name;

    function addSubcollection() {
        const idSuffix = prompt("Enter suffix to append to ID");
        if(!idSuffix)
            return;

        const displayName = prompt("Enter display name of collection");
        if(!displayName)
            return;
        
        const newID = topLevel.id + idSuffix;
        props.onAddSubcollection(displayName, newID, topLevel.id);
    }

    function onClick() {
        if(children.length === 0)
            props.onSelect(props.tlid);
        setExpanded(!expanded);
    }

    if(children.length === 0) {
        return <div className="CollectionEntry">
            <div onClick={onClick} className={titleClass}>
                {buttonTitle}
                {editMode && <div className="CollectionEntry-AddButton" onClick={addSubcollection}>+</div>}
            </div>
        </div>
    }

    return <div className="CollectionEntry">
        <div onClick={onClick} className={titleClass}>
            {buttonTitle}
            {editMode && <div className="CollectionEntry-AddButton" onClick={addSubcollection}>+</div>}
        </div>
        {expanded && (
            <div className="CollectionEntry-Children">
                { children.map(c => <CollectionEntry onAddSubcollection={props.onAddSubcollection} active={props.active} key={c.id} collections={props.collections} tlid={c.id} onSelect={props.onSelect}/>) }
            </div>
        )}
    </div>
}