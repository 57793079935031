import { createContext, useEffect, useState } from 'react';
import './App.css';
import { API } from './api/API';
import { Language } from './api/API.types';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { GamesList } from './pages/GamesList/GamesList';
import { TextDisplayPage } from './pages/TextDisplayPage/TextDisplayPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <Home/>,
        loader: () => {
            const preferredLang = localStorage.getItem("preferredLang") || "en";
            return redirect(`/${preferredLang}`);
        },
    },
    {
        path: "/:activeLang/",
        element: <Home/>,
        errorElement: <Home/>
    },
    {
        path: "/:activeLang/games",
        element: <GamesList/>
    },
    {
        path: "/:activeLang/games/:title",
        element: <TextDisplayPage/>
    },
    {
        path: "/:activeLang/games/:title/:collection",
        element: <TextDisplayPage/>
    }
]);

function App() {
    const [languages, setLanguages] = useState<Language[] | null>(null);
    const loadLanguages = async () => setLanguages(await API.getLanguages());

    useEffect(() => {
        loadLanguages();
    }, [])

    return (
        <div className="App">
            <LanguageContext.Provider value={languages || []}>
                <RouterProvider router={router}/>
            </LanguageContext.Provider>
        </div>
    );
}

export default App;
export const LanguageContext: React.Context<Language[]> = createContext([] as Language[]);
