import { useEffect, useState } from 'react';
import { Game } from '../../api/API.types';
import './GamesList.css';
import { API } from '../../api/API';
import { GameButton } from '../../components/GameButton/GameButton';
import { LocalizedString } from '../../components/LocalizedString/LocalizedString';
import { GAMELIST_L18N_REPO } from './GamesList.l18n';
import { LoadSpinner } from '../../components/LoadSpinner/LoadSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { TitleBar } from '../../components/TitleBar/TitleBar';

export function GamesList() {
    const {activeLang} = useParams();
    const [games, setGames] = useState<Game[] | null>(null);
    const loadGames = async () => setGames(await API.getGames(activeLang as string));
    const navigate = useNavigate();

    useEffect(() => {
        loadGames();
    }, [activeLang]); // eslint-disable-line react-hooks/exhaustive-deps

    const listComponent = games
        ? ( games.length === 0 ? <p><LocalizedString repo={GAMELIST_L18N_REPO}>No games</LocalizedString></p> : games?.map((game, idx) => <GameButton index={idx} key={game.id} game={game} navigate={navigate}/>) )
        : <LoadSpinner/>

    return <>
        <TitleBar/>
        <div className='GamesList'>
            <h1><LocalizedString repo={GAMELIST_L18N_REPO}>Games</LocalizedString></h1>
            <div className='GamesList-Container'>
                {listComponent}
            </div>
        </div>
    </>
}