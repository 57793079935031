import { NavigateFunction } from 'react-router-dom';
import { Game } from '../../api/API.types';
import { LoadableImage } from '../LoadableImage/LoadableImage';
import './GameButton.css';

type GameButtonProps = {
    game: Game,
    navigate: NavigateFunction,
    index: number
}

const GAME_BUTTON_FADE_DELAY = 0.1;

export function GameButton(props: GameButtonProps) {
    const customStyles = {
        animationDelay: `${GAME_BUTTON_FADE_DELAY*props.index}s`
    };

    return <div style={customStyles} className='GameButton' onClick={() => props.navigate(props.game.id)}>
        <LoadableImage height="15vw" width="15vw" alt={props.game.title} url={props.game.cover} className='GameButton-Cover'/>
        <h2>{props.game.title}</h2>
    </div>
}