import { useEffect, useState } from "react";
import { Node } from "../../../api/API.types"
import './ImmersiveTextNode.css';

type ImmersiveTextNodeProps = {
    node: Node,
    lastNode: boolean,
    onContinue: (rmb: boolean) => any,
}

const IMMERSIVE_NODE_LETTER_DELAY = 25;

export function ImmersiveTextNode(props: ImmersiveTextNodeProps) {
    const content = props.node.content;
    const speaker = props.node.speaker;
    const [writtenContent,setWrittenContent] = useState("");
    const [revealTimeout,setRevealTimeout] = useState<NodeJS.Timeout | null>(null);
    const [done,setDone] = useState(false);

    function revealNextCharacter() {
        if(writtenContent.length === content.length) {
            setDone(true);
            return;
        }
        
        let newText = content[writtenContent.length];
        if(newText === "<")
            newText = content.substring(writtenContent.length, content.indexOf(">", writtenContent.length) + 1);
        const newWritten = writtenContent + newText;
        setWrittenContent(newWritten);
    }

    function onClick(rmb: boolean) {
        if(done) {
            props.onContinue(rmb);
        }
        else {
            if(revealTimeout)
                clearTimeout(revealTimeout);
            setWrittenContent(content);
            setDone(true);
        }
    }

    useEffect(() => {setRevealTimeout(setTimeout(revealNextCharacter, IMMERSIVE_NODE_LETTER_DELAY))}, [writtenContent]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {setWrittenContent(""); setDone(false);}, [props.node])

    return <div
        className="ImmersiveTextNode"
        onClick={(e) => {e.preventDefault(); onClick(false);}}
        onContextMenu={(e) => {e.preventDefault(); onClick(true);}}
    >
        {speaker && <div className="ImmersiveTextNode-SpeakerBox">{speaker}</div>}
        <div className="ImmersiveTextNode-TextBox" dangerouslySetInnerHTML={{__html: writtenContent}}/>
        {(done && !props.lastNode) && <div className="ImmersiveTextNode-DoneIcon"/>}
        {(done && props.lastNode) && <div className="ImmersiveTextNode-FinishedIcon"/>}
    </div>
}