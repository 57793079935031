import './CountryFlag.css'

type CountryFlagProps = {
    country: string,
    alt: string
}

const INTERNAL_COUNTRY_OVERRIDES: {[key: string]: string} = {
    "en": "US"
};

export function CountryFlag(props: CountryFlagProps) {
    let countryCode: string = props.country;
    if(Object.keys(INTERNAL_COUNTRY_OVERRIDES).includes(countryCode))
        countryCode = INTERNAL_COUNTRY_OVERRIDES[countryCode];
    return <img
        className='CountryFlag'
        alt={props.alt}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`}
    />
}