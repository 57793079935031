import { Node } from '../../../api/API.types';
import { TextNode } from '../TextNode/TextNode';
import './NodeList.css';

type NodeListProps = {
    nodes: Node[],
    onAppendNode: (id: number, content: string, speaker: string | null, merge: boolean) => any
}

type NodeListElement = Array<JSX.Element | NodeListElement> | null;

export function NodeList(props: NodeListProps) {

    function buildList(prev: number | null = null, stopAtMerge = false): NodeListElement {
        const matchingNodes = props.nodes.filter(n => n.prev === prev);
        if(matchingNodes.length === 0)
            return null;
        else if(matchingNodes.length === 1) {
            const node = matchingNodes[0];
            if(stopAtMerge && node.merge)
                return null;
            return [<TextNode node={node} onAppendNode={props.onAppendNode}/>, buildList(node.id, stopAtMerge)];
        }

        const multiColumns = <div className="NodeList-ColumnContainer">
            {matchingNodes.map(n => {
                return <div className="NodeList-Column">
                    {[<TextNode node={n} onAppendNode={props.onAppendNode}/>, buildList(n.id, true)]}
                </div>
            })}
        </div>;
        const mergeParentCheckIDs = matchingNodes.map(n => n.id);
        let mergeID = null;
        while(mergeParentCheckIDs.length > 0) {
            const checkID = mergeParentCheckIDs.shift();
            const checkNodes = props.nodes.filter(n => n.prev === checkID);
            for(let checkNode of checkNodes) {
                if(checkNode.merge) {
                    mergeID = checkID;
                    mergeParentCheckIDs.splice(0, mergeParentCheckIDs.length);
                    break;
                }
                else
                    mergeParentCheckIDs.push(checkNode.id);
            }
        }

        if(mergeID)
            return [multiColumns, buildList(mergeID)]
        return [multiColumns];
    }

    return <>
        {buildList()}
    </>
}