import { useEffect, useState } from 'react';
import { Node } from '../../../api/API.types';
import './NodeFlowContainer.css';
import { API } from '../../../api/API';
import { LocalizedString } from '../../LocalizedString/LocalizedString';
import { NODE_FLOW_L18N_REPO } from './NodeFlowContainer.l18n';
import { LoadSpinner } from '../../LoadSpinner/LoadSpinner';
import { ImmersiveTextNode } from '../ImmersiveTextNode/ImmersiveTextNode';
import { Button } from '../../Button/Button';
import { useParams } from 'react-router-dom';
import { NodeList } from '../NodeList/NodeList';

type NodeFlowContainerProps = {
    game: string,
    collection?: string,
    immersive: boolean,
}

export function NodeFlowContainer(props: NodeFlowContainerProps) {
    const editMode = process.env.NODE_ENV === "development";
    const [nodes, setNodes] = useState<Node[] | null>(null);
    const [immersiveIndex, setImmersiveIndex] = useState(0);
    const {activeLang} = useParams();
    const loadNodes = async () => setNodes(props.collection ? await API.getNodes(activeLang as string, props.game, props.collection) : []);

    useEffect(() => {
        setNodes(null);
        loadNodes();
        setImmersiveIndex(0);
    }, [activeLang, props.collection]); // eslint-disable-line react-hooks/exhaustive-deps

    async function onAppendNode(id: number | null, content: string, speaker: string | null, merge: boolean) {
        setNodes(null);
        const res = await API.createNode(activeLang as string, props.game, props.collection as string, content, speaker, id, merge);
        if(res.status !== 201)
            alert("Error: " + res.status);
        loadNodes();
        setImmersiveIndex(0);
    }

    function onImmersiveContinue(rmb: boolean) {
        const offset = rmb ? -1 : 1;
        const newIndex = Math.min(Math.max(0, immersiveIndex+offset), (nodes as Node[]).length-1);
        setImmersiveIndex(newIndex);
    }

    async function appendNodeToEnd() {
        if(!nodes)
            return;

        const content = prompt("Enter node content");
        if(!content)
            return;
        let speaker = prompt("Enter Speaker for Node (leave empty for no speaker)");
        if(speaker === null)
            return;
        if(!speaker)
            speaker = null;
        let merge = !!prompt("Should node merge previous node trees? Leave empty for no, type anything for yes.");

        const prevID = nodes.length === 0 ? null : nodes[nodes.length - 1].id
        onAppendNode(prevID, content, speaker, merge);
    }

    let component = null;
    if(!props.collection)
        component = <p className='NodeFlowContainer-EmptyLabel'><LocalizedString repo={NODE_FLOW_L18N_REPO}>Select Collection</LocalizedString></p>
    else if(nodes === null)
        component = <div className='NodeFlowContainer'><br/><br/><LoadSpinner/></div>
    else if(nodes?.length === 0)
        component = <p className='NodeFlowContainer-EmptyLabel'><LocalizedString repo={NODE_FLOW_L18N_REPO}>No Data</LocalizedString></p>
    else if(!props.immersive) {
        component = <div className="NodeFlowContainer">
            <NodeList nodes={nodes} onAppendNode={onAppendNode}/>
        </div>
    } else {
        component = <div className='NodeFlowContainer immersive'>
            <ImmersiveTextNode onContinue={onImmersiveContinue} node={nodes[immersiveIndex]} lastNode={immersiveIndex === nodes.length - 1}/>
            <p className='NodeFlowContainer-ImmersiveModeInstructions'><LocalizedString repo={NODE_FLOW_L18N_REPO}>Immersive Mode Instructions</LocalizedString></p>
        </div>
    }

    return <>
        {editMode && <Button onClick={appendNodeToEnd}><LocalizedString repo={NODE_FLOW_L18N_REPO}>Append To End</LocalizedString></Button>}
        {component}
    </>
}