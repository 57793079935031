import './TextNode.css';
import { Node } from "../../../api/API.types"

type TextNodeProps = {
    node: Node,
    onAppendNode: (id: number, content: string, speaker: string | null, merge: boolean) => any
}

export function TextNode(props: TextNodeProps) {
    const editMode = process.env.NODE_ENV === "development";

    async function appendNode() {
        const content = prompt("Enter node content");
        if(!content)
            return;
        let speaker = prompt("Enter Speaker for Node (leave empty for no speaker)");
        if(speaker === null)
            return;
        if(!speaker)
            speaker = null;
        let merge = !!prompt("Should node merge previous node trees? Leave empty for no, type anything for yes.");
        props.onAppendNode(props.node.id, content, speaker, merge);
    }

    return <div className={"TextNode" + (props.node.speaker ? "" : " nospeaker")}>
        {props.node.speaker && <div className='TextNode-SpeakerBox'>{props.node.speaker}</div>}
        <div className='TextNode-TextBox' dangerouslySetInnerHTML={{__html: props.node.content}}/>
        {editMode && <div className='TextNode-AddButton' onClick={() => appendNode()}>+</div>}
    </div>
}